import React, { ChangeEvent, FC, useState } from 'react';
import s from './AdminDrawCard.module.scss';
import { EditableDrawTitle } from './editableDrawTitle/EditableDrawTitle';
import { EditButton } from './editableDrawTitle/EditButton';
import { HideButton } from './editableDrawTitle/HideButton';
import { useAppDispatch } from '../../../../common/hooks/storeHooks';
import { DrawsCategoryType, RAFFLES_STATUSES } from '../AdminDraws';
import clsx from 'clsx';
import { adminRafflesThunks } from '../../adminDrawsSlice';
import { RaffleType } from '../../API/adminDrawsAPI';
import { PortalModal } from '../../../../common/components/modal/PortalModal';
import { AdminDrawCardModal } from '../adminDrawCardModal/AdminDrawCardModal';
import { PATH } from '../../../../common/path/puth';
import { CLIENT_BASE_URL } from '../../../../common/constants/links';
import { setWinnersDisplaying } from '../../utils';
import { PictureRaffle } from '../../../../common/components/PictureRaffle';

type PropsType = {
  drawData: RaffleType;
  getRafflesAfterEdit: () => void;
  drawsCategory: DrawsCategoryType;
};

export const AdminDrawCard: FC<PropsType> = ({ getRafflesAfterEdit, drawsCategory, drawData }) => {
  const { id, header, fileName, originalHeader, description, bot_id, end_timestamp, customer_id, winners } = drawData;

  const [editMode, setEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editableHeader, setEditableHeader] = useState(header);
  const dispatch = useAppDispatch();

  const toEditMode = () => setEditMode(true);
  const outOfEditMode = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setEditMode(false);
      setEditableHeader(header);
    }
  };
  const closeModal = () => setOpenModal(false);
  const showDrawDetails = () => {
    const isModalShouldOpen =
      drawsCategory === RAFFLES_STATUSES.Active || drawsCategory === RAFFLES_STATUSES.HiddenActive;

    if (isModalShouldOpen) {
      setOpenModal(true);
    } else {
      window.open(`${CLIENT_BASE_URL}${PATH.result}/${customer_id}/${bot_id}/${id}`, '_blank');
    }
  };

  const changeTitleHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEditableHeader(e.currentTarget.value);
  };
  const onChangeDrawHeader = () => {
    setEditMode(false);
    dispatch(
      adminRafflesThunks.adminEditRaffle({
        params: { editableHeader },
        raffleId: id,
      })
    )
      .unwrap()
      .then(() => getRafflesAfterEdit());
  };
  const onChangeVisibilityDraw = (isHidden: boolean) => {
    dispatch(
      adminRafflesThunks.adminEditRaffle({
        params: { isHidden },
        raffleId: id,
      })
    )
      .unwrap()
      .then(() => getRafflesAfterEdit());
  };

  const endDrawsVariant = drawsCategory === RAFFLES_STATUSES.End;
  const activeDrawVariant = drawsCategory === RAFFLES_STATUSES.Active;
  const hiddenDrawsVariant =
    drawsCategory === RAFFLES_STATUSES.HiddenEnd || drawsCategory === RAFFLES_STATUSES.HiddenActive;

  const disabledDrawCardClassName = clsx(hiddenDrawsVariant && s.disabledCard);
  const drawCardHideBtnClassName = clsx(hiddenDrawsVariant ? s.drawCardShowBtn : s.drawCardHideBtn);

  const winnersDisplaying = setWinnersDisplaying(winners.length);

  return (
    <div className={s.drawCard}>
      <div className={disabledDrawCardClassName}>
        <PictureRaffle onClick={showDrawDetails} className={s.drawImage} src={fileName} alt='draw_avatar' />
        <EditableDrawTitle
          outOfEditMode={outOfEditMode}
          editMode={editMode}
          editableHeader={editableHeader}
          changeTitleHandler={changeTitleHandler}
        />
      </div>
      {endDrawsVariant && winners.length && (
        <span
          className={s.drawWinner}
        >{`${winnersDisplaying.title} @${winners[0].telegramName}${winnersDisplaying.dots}`}</span>
      )}
      <div className={s.drawCardButtonsWrapper}>
        {activeDrawVariant && (
          <EditButton toEditMode={toEditMode} editMode={editMode} onChangeDrawHeader={onChangeDrawHeader} />
        )}
        {!hiddenDrawsVariant && (
          <HideButton
            drawCardHideBtnClassName={drawCardHideBtnClassName}
            onChangeVisibilityDraw={onChangeVisibilityDraw}
            isHidden={true}
            title={'Скрыть'}
          />
        )}
        {hiddenDrawsVariant && (
          <HideButton
            drawCardHideBtnClassName={drawCardHideBtnClassName}
            onChangeVisibilityDraw={onChangeVisibilityDraw}
            isHidden={false}
            title={'Показать'}
          />
        )}
      </div>
      {openModal && (
        <PortalModal closeModalOnOutsideClick={closeModal} className={s.portalModalWrapper}>
          <AdminDrawCardModal
            originalHeader={originalHeader}
            description={description}
            fileName={fileName}
            endTimestamp={end_timestamp}
            closeModal={closeModal}
          />
        </PortalModal>
      )}
    </div>
  );
};
