import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdminMailingFormFields,
  ButtonUsage,
  MailingRaffleReturnType,
  MailingRaffleThunkParams,
} from '../types';
import { mailingRaffle, mailingRaffleTest } from '../api';
import { thunkErrorHandler } from '../../../../common/utils/thunkErrorHandler';
import { agesConverter, AgesDisplayValues, genderConverter, GenderDisplayValues } from '../../../../common/shemas';
import { RootState } from '../../../../bll/store/store';

async function convertToFormData(fields: AdminMailingFormFields): Promise<FormData> {
  const formData = new FormData();

  if (fields.countries.length > 1 && !fields.countries.includes('all')) {
    formData.append('countries', JSON.stringify(fields.countries));
  }

  if (fields.ages.length > 1 && !fields.ages.includes(AgesDisplayValues.ALL)) {
    const agesFormData = fields.ages.map(fieldAge => agesConverter[fieldAge as keyof typeof agesConverter]);
    formData.append('ages', JSON.stringify(agesFormData));
  }

  if (!fields.gender.includes(GenderDisplayValues.ALL)) {
    fields.gender.forEach(selectionValue => {
      formData.append('gender', genderConverter[selectionValue as keyof typeof genderConverter]);
    });
  }

  formData.append('hasButton', JSON.stringify(fields.buttonUsage === ButtonUsage.YES));
  formData.append('description', fields.description);
  formData.append('buttonTitle', fields.buttonText);
  formData.append('link', fields.buttonLink);

  let blob;
  let asyncFetch: Function;

  if (typeof fields.file === 'string') {
    asyncFetch = async () => {
      await fetch(`${fields.file}`, { cache: 'no-cache' })
        .then(res => res.blob())
        .then(res => {
          blob = new File([res], fields.file.slice(69 + 1, fields.file.length), {
            type: `image/${fields.file.slice(69 + 1, fields.file.length)}`,
          });
          formData.append('file', blob);
        });
    };
  } else {
    formData.append('file', fields.file);
  }

  const formDataAsync = async () => {
    await asyncFetch();
    return formData;
  };

  //@ts-ignore
  if (asyncFetch) {
    return await formDataAsync();
  } else {
    return formData;
  }
}

export const mailingRaffleTestAsyncAction = createAsyncThunk<MailingRaffleReturnType, AdminMailingFormFields>(
  'adminMailing/mailingRaffleTestAsyncAction',
  async (params, thunkAPI) => {
    try {
      const formData = await convertToFormData(params);
      const response = await mailingRaffleTest(formData);
      return response.data;
    } catch (error) {
      thunkErrorHandler(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const mailingRaffleAsyncAction = createAsyncThunk(
  'adminMailing/mailingRaffleAsyncAction',
  async (data: MailingRaffleThunkParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const raffleIdForMailing = state.adminMailing.raffleId;
    if (!raffleIdForMailing) {
      return thunkAPI.rejectWithValue('Raffle id is not set');
    }
    try {
      const response = await mailingRaffle({ ...data, raffleId: raffleIdForMailing });
      return response.data;
    } catch (error) {
      thunkErrorHandler(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(null);
    }
  }
);
