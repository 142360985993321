import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import s from './RangeCalendar.module.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import dayjs, { Dayjs } from 'dayjs';
import { ru } from 'date-fns/locale';
import { Typography } from '../../../../../../components/typography/Typography';

type Props = {
  startDateValue: null | Dayjs;
  endDateValue: null | Dayjs;
  isActive: boolean;
  setStartDay: (value: Dayjs) => void;
  setEndDay: (value: Dayjs) => void;
  setDiff: (value: number) => void;
};

export const RangeCalendar = ({ startDateValue, endDateValue, isActive, setStartDay, setEndDay, setDiff }: Props) => {
  const currDay = new Date();
  const minDate = new Date('01.01.2023');
  const currStartDay = startDateValue ? startDateValue.toDate() : new Date();
  const currEndDay = endDateValue ? endDateValue.toDate() : new Date();
  const [selectionRange, setSelectionRange] = useState({});

  useEffect(() => {
    isActive &&
      setSelectionRange({
        startDate: currStartDay,
        endDate: currEndDay,
        key: 'selection',
      });
  }, [isActive]);

  const handleSelect = (ranges: any) => {
    setSelectionRange(ranges.selection);
    setStartDay(dayjs(ranges.selection.startDate));
    setEndDay(dayjs(ranges.selection.endDate));
    setDiff(dayjs(ranges.selection.endDate).diff(dayjs(ranges.selection.startDate)));
  };

  // @ts-ignore
  return (
    <div className={isActive ? `${s.rangeCalendar} ${s.active}` : s.rangeCalendar}>
      <Typography variant={'small_text'} mb={'1.2rem'} mx={'1.2rem'} mt={'0.8rem'}>
        Выбор периода
      </Typography>
      {/*// @ts-ignore */}
      <DateRange
        locale={ru}
        ranges={[selectionRange]}
        onChange={handleSelect}
        showDateDisplay={false}
        maxDate={currDay}
        minDate={minDate}
        weekdayDisplayFormat='EEEEEE'
        fixedHeight={true}
      />
    </div>
  );
};
