import React, { useMemo } from 'react';
import styles from './AdminMailingTextArea.module.scss';
import { TitleDraw } from '../../../../../customer/components/createDraw/components/TitleDraw';
import { FormikValues } from 'formik';
import TextField from '@mui/material/TextField';

type PropsType = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  formik: FormikValues;
  fieldName: string;
};

export const AdminMailingTextArea = React.memo(({ formik, fieldName }: PropsType) => {
  const isError = useMemo(() => {
    return !!(formik.touched[fieldName] && formik.errors[fieldName]);
  }, [formik.touched[fieldName], formik.errors[fieldName]]);

  return (
    <div className={styles.container}>
      <div className={styles.titleTextAreaBlock}>
        <TitleDraw title={'Текст'} />
      </div>
      <div className={styles.textareaContainer}>
        <TextField
          multiline
          sx={{
            '& fieldset': {
              border: isError ? '1px solid red' : 'none',
            },
          }}
          minRows={12}
          id={'text'}
          name={'text'}
          className={styles.textarea}
          InputProps={{ sx: { fontSize: '16px' } }}
          FormHelperTextProps={{ sx: { fontSize: '12px' } }}
          {...formik.getFieldProps(fieldName)}
          error={isError}
          helperText={formik.touched[fieldName] && formik.errors[fieldName]}
        />
      </div>
    </div>
  );
});
