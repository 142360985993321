import { FC, memo, useEffect, useState } from 'react';
import { useValidImage } from '../hooks/checkImage';
import { Loader } from './loader/Loader';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

export type PictureProps = {
  src: string;
  alt?: string;
  className?: string;
  [key: string]: any;
};
export const PictureRaffle: FC<PictureProps> = memo(({ src, className, alt = 'Raffle', ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);

  const isBase64Image = src.startsWith('data:image');
  const srcSmall = isBase64Image ? src : `https://telegiv.s3.eu-central-1.amazonaws.com/raffles/avatar/260X260_${src}`;
  const srcAverage = isBase64Image
    ? src
    : `https://telegiv.s3.eu-central-1.amazonaws.com/raffles/avatar/460X460_${src}`;
  const srcBackUp = isBase64Image ? src : `https://telegiv.s3.eu-central-1.amazonaws.com/raffles/avatar/${src}`;

  const { isImage, checkImage } = useValidImage();
  useEffect(() => {
    setIsLoading(true);
    const validateAndSetImage = async () => {
      await checkImage(srcBackUp);
      setIsLoading(false);
    };
    validateAndSetImage();
  }, [src]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <picture className={className} {...rest}>
      <source srcSet={isImage ? srcBackUp : srcSmall} media='(min-width: 328px)' />
      <source srcSet={isImage ? srcBackUp : srcAverage} media='(min-width: 416px)' />
      <img className={className} src={isImage ? srcBackUp : srcAverage} alt={alt} />
    </picture>
  );
});
