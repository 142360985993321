import React, { useEffect } from 'react';
import { ManagerInfoBlock } from './managerInfoBlock/ManagerInfoBlock';
import { ManagerStatsBlock } from './managerStatsBlock/ManagerStatsBlock';
import { ManagerClients } from './clients/ManagerClients';
import { ManagerTeam } from './team/ManagerTeam';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { publicManagerThunks } from '../../publicManagerSlice';
import { isFetchingSelector } from '../../managerPublicSelectors';
import { PreLoader } from '../../common/components/preLoader/PreLoader';

export const SalesHome = () => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);

  useEffect(() => {
    dispatch(publicManagerThunks.getInfo());
    dispatch(publicManagerThunks.getClients({}));
    dispatch(publicManagerThunks.getTeam({}));
  }, []);

  return isFetching ? (
    <PreLoader />
  ) : (
    <>
      <ManagerInfoBlock />
      <ManagerStatsBlock />
      <ManagerClients />
      <ManagerTeam />
    </>
  );
};
